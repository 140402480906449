import ChannelDial from "@/components/Icons/ChannelDial";
import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import Locator from "@/locator";
import { ActionContext } from "@/models/ActionsProvider";
import { DataContext } from "@/models/DataProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { UxContext } from "@/models/UxStateProvider";
import { getRandomTeamname, handleErrorResponse } from "@/utils";
import { Help, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useToggle } from "@uidotdev/usehooks";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import { WsFeed } from "web-client/api/data-contracts";
import { FullInput, FullTooltip } from "./Utils";

export default function ChannelModal() {
  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const [newChannelName, setNewChannelName] = useState("");
  const [openTooltip, setTooltipOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const [channelTypeOpen, toggleChannelTypeOpen] = useToggle(true);
  const [channelSettingsOpen, toggleChannelSettingsOpen] = useToggle(false);
  const [handsFreePlayback, toggleHandsFreePlayback] = useToggle(true);
  const [readOnlyFeed, toggleReadOnly] = useToggle(false);
  const [autoSubscribeNewUsers, toggleAutoSubscribe] = useToggle(false);

  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const handleChannelTypeChange = (value) => {
    if (value) {
      toggleAutoSubscribe(false);
    }
    setIsPrivate(() => value);
  };
  const isSilent = !handsFreePlayback ? true : false;
  const permanentSettingsRef = useRef([]);

  const { currentFeedId, myAccount, isWorkspaceLimitedMember, currentFeed } =
    useContext(DataContext);
  const limitedMember = isWorkspaceLimitedMember(workspaceId, myAccount?.id);
  const { createWorkspaceFeed, updateWorkspaceFeed } =
    useContext(ActionContext);
  const {
    channelModalOpen,
    setChannelModalOpen,
    editChannelSettings,
    channelModalNew,
  } = useContext(UxContext);
  const { ampli } = useContext(TrackingContext);
  const theme = useTheme();
  const { db } = useElectric();
  const channelNameCharLimit = 40;

  const handleClose = useCallback(() => {
    setChannelModalOpen(false);
  }, [setChannelModalOpen]);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const channelTypeInfo = {
    private: {
      title: "Private Channel",
      description: "Users can join this channel by owner invitation.",
    },
    public: {
      title: "Public Channel",
      description: "Everyone can find and join this channel.",
    },
    silentMode: {
      title: "Hands-free playback mode",
      description: "Messages will play automatically in hands-free mode.",
    },
    listenOnly: {
      title: "Listen-only mode",
      description:
        "Only you can send messages in this channel. This cannot be undone.",
    },
    autoSubscribe: {
      title: "Automatically invite new users",
      description:
        "All new employees will be automatically added to this channel.",
    },
  };

  const createFeedAction = async () => {
    let newWorkspaceFeed: WsFeed;
    const randomTeamName = getRandomTeamname();
    const channelName = newChannelName || randomTeamName;
    setNewChannelName(channelName);

    let newFeedId: string;

    try {
      // disable the button while creating a feed
      setSaving(true);
      newWorkspaceFeed = await createWorkspaceFeed(
        workspaceId,
        channelName,
        isPrivate,
        readOnlyFeed,
        isSilent,
        autoSubscribeNewUsers,
      );

      toggleAutoSubscribe(false);
      toggleHandsFreePlayback(true);
      toggleAutoSubscribe(false);
      toggleReadOnly(false);
      handleChannelTypeChange(true);
      toggleChannelSettingsOpen(false);

      ampli.feedCreate({
        teamName: channelName,
        feedId: newWorkspaceFeed.id,
        listenOnly: readOnlyFeed,
      });
    } catch (e) {
      const error = await handleErrorResponse(e);
      showBoundary(error);
    } finally {
      handleClose();
      setNewChannelName("");
      toggleReadOnly(false);
      setSaving(false);
      if (newWorkspaceFeed) {
        navigate(`/workspaces/${workspaceId}/feeds/${newWorkspaceFeed.id}`);
      }
    }
  };

  const updateFeedAction = useCallback(async () => {
    try {
      setSaving(true);
      await updateWorkspaceFeed(
        workspaceId,
        currentFeedId,
        newChannelName,
        isSilent,
      );
    } catch (e) {
      const error = await handleErrorResponse(e);
      showBoundary(error);
    } finally {
      setSaving(false);
      setChannelModalOpen(false);
    }
  }, [
    workspaceId,
    currentFeedId,
    showBoundary,
    newChannelName,
    isSilent,
    updateWorkspaceFeed,
    setChannelModalOpen,
  ]);

  const handleClick = useCallback(async () => {
    if (!editChannelSettings) {
      await createFeedAction();
    } else {
      await updateFeedAction();
    }
  }, [editChannelSettings, createFeedAction, updateFeedAction]);

  useEffect(() => {
    if (!editChannelSettings) {
      if (myAccount?.name) {
        setNewChannelName(`${myAccount?.name}'s Channel`);
      } else {
        setNewChannelName("Your Channel");
      }
    } else {
      db.feed
        .findUnique({
          where: {
            id: currentFeedId,
          },
        })
        .then((feed) => {
          console.log("UPDATES", { feed });
          if (feed?.title) {
            setIsPrivate(() => feed?.isPrivate === 1);
            setNewChannelName(feed?.title);
          }
        });
      // need setting to determine if feed was set to readonly here.
    }
  }, [editChannelSettings, currentFeedId, myAccount]);

  useEffect(() => {
    if (channelModalOpen && editChannelSettings) {
      const handsFreeMode = currentFeed?.isSilent === 0 ? true : false;
      const autoJoin = currentFeed?.autoJoin === 1 ? true : false;
      const readOnly = currentFeed?.readOnly === 1 ? true : false;
      console.log(permanentSettingsRef.current);
      permanentSettingsRef.current = [
        currentFeed?.isPrivate === 1 ? "private" : "public",
        readOnly ? "listenOnly" : "",
      ];
      toggleHandsFreePlayback(handsFreeMode);
      toggleAutoSubscribe(autoJoin);
      toggleReadOnly(readOnly);
    }
  }, [channelModalOpen, editChannelSettings]);

  const ChannelNameLabel = () => {
    const displayTitle = (
      <Stack sx={{ gap: 1 }}>
        <Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>
          Naming a channel
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>
          When naming a channel, it is best to be specific and brief, so that
          the names are easy to recall and say when using voice commands. Avoid
          using special characters or emojis in your channel name.
        </Typography>
      </Stack>
    );

    return (
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          gap: 1,
        }}
      >
        <Typography>Channel name</Typography>
        <FullTooltip
          describeChild
          title={displayTitle}
          placement="bottom-start"
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          slotProps={{
            tooltip: {
              sx: {
                "&.MuiTooltip-tooltip": {
                  padding: 2.25,
                  width: "100%",
                  maxWidth: 400,
                },
              },
            },
            popper: {
              sx: {
                '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
                  {
                    mt: 1,
                  },
              },
            },
          }}
        >
          <IconButton
            sx={{
              p: 0,
              borderRadius: "1000px",
              "&:hover:not([disabled])": {
                background: "transparent",
              },
            }}
          >
            <Help
              role="img"
              sx={{
                fontSize: "1.25rem",
                color: openTooltip
                  ? theme.palette.info.light
                  : theme.palette.primary.main,
              }}
            />
          </IconButton>
        </FullTooltip>
      </Stack>
    );
  };

  const ChannelNameHelperText = ({ characterLimit, field }) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: 1,
          width: "100%",
        }}
      >
        <Typography>
          {field?.length}/{characterLimit}
        </Typography>
      </Box>
    );
  };

  const ListenOnlyMode = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          mt: 1,
          p: 2,
          background: theme.palette.secondary.main,
          borderRadius: 2,
          gap: 2,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {channelTypeInfo.listenOnly.title}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.neutral.light,
              fontSize: "0.875rem",
            }}
          >
            {channelTypeInfo.listenOnly.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            justifyContent: "end",
            display: "flex",
          }}
        >
          <Checkbox
            checked={readOnlyFeed}
            aria-label={Locator.workspaceNav.channels.modal.listenOnly}
            sx={{
              "&.Mui-checked": {
                color: theme.palette.primary.main,
              },
            }}
            onChange={() => toggleReadOnly(!readOnlyFeed)}
          />
        </Box>
      </Stack>
    );
  };

  const SilentMode = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          mt: 1,
          p: 2,
          background: theme.palette.secondary.main,
          borderRadius: 2,
          gap: 2,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {channelTypeInfo.silentMode.title}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.neutral.light,
              fontSize: "0.875rem",
            }}
          >
            {channelTypeInfo.silentMode.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            justifyContent: "end",
            display: "flex",
          }}
        >
          <Checkbox
            checked={handsFreePlayback}
            aria-label={Locator.workspaceNav.channels.modal.silent}
            disabled={!channelModalNew && limitedMember}
            sx={{
              "&.Mui-checked": {
                color: theme.palette.primary.main,
              },
            }}
            onChange={() => toggleHandsFreePlayback(!handsFreePlayback)}
          />
        </Box>
      </Stack>
    );
  };

  const AutoSubscribe = () => {
    return (
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          mt: 1,
          p: 2,
          background: theme.palette.secondary.main,
          borderRadius: 2,
          gap: 2,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {channelTypeInfo.autoSubscribe.title}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.neutral.light,
              fontSize: "0.875rem",
            }}
          >
            {channelTypeInfo.autoSubscribe.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            justifyContent: "end",
            display: "flex",
          }}
        >
          <Checkbox
            checked={autoSubscribeNewUsers}
            aria-label={Locator.workspaceNav.channels.modal.autoSubscribe}
            disabled={!channelModalNew && limitedMember}
            sx={{
              "&.Mui-checked": {
                color: theme.palette.primary.main,
              },
            }}
            onChange={() => toggleAutoSubscribe(!autoSubscribeNewUsers)}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <ModalForm
      id="new-channel"
      maxHeight={785}
      open={channelModalOpen}
      onClose={handleClose}
      disableClose={saving}
      sx={{
        padding: { xs: "40px 70px" },
        px: { xs: "6px", sm: "55px" },
        gap: 2,
      }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            fontWeight: 500,
            gap: 1.5,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" component="h3" sx={{ fontWeight: 700 }}>
            {!editChannelSettings ? "Create a new channel" : "Channel settings"}
          </Typography>
          {!editChannelSettings ? (
            <Typography>
              Channels are conversations with one or more users in your
              Workspace.
            </Typography>
          ) : null}
        </Box>

        <FullInput
          id="new-channelname"
          aria-label={Locator.workspaceNav.channels.modal.name}
          label={<ChannelNameLabel />}
          placeholder="E.g. Weekly updates, Carol/Peter,"
          disabled={saving}
          value={newChannelName}
          callback={(e) => setNewChannelName(e.target.value)}
          error={newChannelName?.length > channelNameCharLimit}
          formControlProps={{
            sx: {
              "& .MuiFormHelperText-root": {
                mb: 0,
              },
            },
          }}
          helperText={
            <ChannelNameHelperText
              characterLimit={channelNameCharLimit}
              field={newChannelName}
            />
          }
        />

        {editChannelSettings ? (
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: 500,
                pb: 0.5,
              }}
            >
              Selected channel type:
            </Typography>
            <Stack
              sx={{
                p: 2,
                background: theme.palette.secondary.main,
                borderRadius: 2,
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Stack sx={{ gap: 1 }}>
                  {permanentSettingsRef.current
                    ?.filter((setting) => setting)
                    .map((setting) => (
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          {channelTypeInfo[setting].title}
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.neutral.light,
                            fontSize: "0.875rem",
                          }}
                        >
                          {channelTypeInfo[setting].description}
                        </Typography>
                      </Box>
                    ))}
                </Stack>
                <Box
                  sx={{
                    width: "120px",
                    justifyContent: "end",
                    display: "flex",
                  }}
                >
                  <ChannelDial />
                </Box>
              </Stack>
            </Stack>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Button
              variant="text"
              sx={{
                textTransform: "none",
                width: "max-content",
                alignSelf: "flex-start",
                fontWeight: 500,
                p: 0,
                "&:hover": {
                  background: "transparent",
                },
              }}
              onClick={() => toggleChannelTypeOpen(!channelTypeOpen)}
            >
              Select a channel type
              {channelTypeOpen ? (
                <KeyboardArrowUp role="img" />
              ) : (
                <KeyboardArrowDown role="img" />
              )}
            </Button>
            <Collapse in={channelTypeOpen}>
              <Stack
                sx={{
                  height: "100%",
                  p: 2,
                  background: theme.palette.secondary.main,
                  borderRadius: 2,
                  gap: 2,
                }}
              >
                <label>
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Public Channel
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.neutral.light,
                          fontSize: "0.875rem",
                        }}
                      >
                        Everyone can find and join this channel.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "120px",
                        justifyContent: "end",
                        display: "flex",
                      }}
                    >
                      <Radio
                        disabled={!channelModalNew && limitedMember}
                        checked={!isPrivate}
                        aria-label={Locator.workspaceNav.channels.modal.public}
                        onClick={() => handleChannelTypeChange(false)}
                      />
                    </Box>
                  </Stack>
                </label>
                {!limitedMember ? (
                  <label>
                    <Stack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          Private Channel
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.neutral.light,
                            fontSize: "0.875rem",
                          }}
                        >
                          Users can join this channel by owner invitation.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "120px",
                          justifyContent: "end",
                          display: "flex",
                        }}
                      >
                        <Radio
                          disabled={!channelModalNew && limitedMember}
                          checked={isPrivate}
                          aria-label={
                            Locator.workspaceNav.channels.modal.private
                          }
                          onClick={() => handleChannelTypeChange(true)}
                        />
                      </Box>
                    </Stack>
                  </label>
                ) : null}
              </Stack>
            </Collapse>
          </Box>
        )}
        <Box sx={{ width: "100%" }}>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              width: "max-content",
              alignSelf: "flex-start",
              fontWeight: 500,
              p: 0,
              "&:hover": {
                background: "transparent",
              },
            }}
            onClick={() => toggleChannelSettingsOpen(!channelSettingsOpen)}
          >
            Channel Settings
            {channelSettingsOpen ? (
              <KeyboardArrowUp role="img" />
            ) : (
              <KeyboardArrowDown role="img" />
            )}
          </Button>
          <Collapse in={channelSettingsOpen}>
            <Stack sx={{ gap: 1.25 }}>
              {SilentMode()}
              {!editChannelSettings || (channelModalNew && !limitedMember)
                ? ListenOnlyMode()
                : null}
              {!isPrivate ? AutoSubscribe() : null}
            </Stack>
          </Collapse>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 2.5,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
            disabled={saving}
            aria-label={Locator.workspaceNav.channels.modal.cancel}
            sx={{
              width: { xs: "100%", sm: "auto" },
              order: { xs: 1, sm: 0 },
              flexGrow: 1,
              flexBasis: "100%",
            }}
          >
            CANCEL
          </Button>
          <LoadingButton
            loading={saving}
            disabled={newChannelName?.length > channelNameCharLimit}
            variant="contained"
            color="primary"
            onClick={handleClick}
            aria-label={
              !editChannelSettings
                ? Locator.workspaceNav.channels.modal.create
                : Locator.workspaceNav.channels.modal.save
            }
            sx={{
              width: { xs: "100%", sm: "auto" },
              order: { xs: 0, sm: 1 },
              flexGrow: 1,
              flexBasis: "100%",
            }}
          >
            {!editChannelSettings ? "CREATE CHANNEL" : "Save"}
          </LoadingButton>
        </Box>
      </>
    </ModalForm>
  );
}
