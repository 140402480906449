import { useElectric } from "@/electric/ElectricWrapper";
import ModalForm from "@/elements/ModalForm";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
export default function SqlEditor({
  open,
  close,
}: { open: boolean; close: () => void }) {
  const { db } = useElectric();
  const [results, setResults] = useState<any[]>([]);
  const handleClose = () => {
    setResults(() => []);
    console.clear();
    close();
  };

  const submit = async (e) => {
    e.preventDefault();

    try {
      const data = await db.raw({ sql: e.target[0].value, args: [] });
      console.clear();
      console.table(data);
      setResults(() => data);
    } catch (e) {
      console.log({ e });
      setResults(() => e);
    }
  };

  return (
    <ModalForm open={open} onClose={() => handleClose()}>
      <form style={{ width: "100%" }} onSubmit={(e) => submit(e)}>
        <textarea
          rows={10}
          style={{
            outline: "none",
            padding: "10px",
            width: "100%",
            border: "none",
            color: "#fff",
            background: "#111",
            resize: "none",
          }}
        />
        <Button type="submit">Submit</Button>
        <pre
          style={{
            background: "#222",
            maxHeight: "250px",
            overflowY: "auto",
            fontSize: "12px",
          }}
        >
          {JSON.stringify(results, null, 2)}
        </pre>
      </form>
    </ModalForm>
  );
}
